import React from 'react';

import Layout from '~/components/Layout';
import Seo from '~/components/seo';

import BannerComponent from '~/components/HelpLine/Banner';
import Intro from '~/components/HelpLine/Intro';
import MediaWill from '~/components/HelpLine/MediaWillHelpline';
import Report from '~/components/HelpLine/Report';

export default function HelpLinePage() {
  return (
    <Layout>
      <div className="Wrap">
        <BannerComponent />
        <Intro />
        <MediaWill />
        <Report />
      </div>
    </Layout>
  );
}

export function Head() {
  return (
    <Seo title="스테이션3 헬프라인" />
  );
}
