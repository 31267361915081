import React from 'react';
import { Wrap, HelpLine } from './styled';

function HelpLineFlowComponent() {
  return (
    <Wrap>
      <div>
        <HelpLine />
      </div>
    </Wrap>
  );
}

export default HelpLineFlowComponent;
